import { forwardRef } from 'react';
import styled from 'styled-components';
import { Box } from 'theme-ui';

const TooltipWrapper = styled(Box)`
    display: inline;

    ${({ $hover }) =>
        $hover &&
        `
        .tooltipText {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        &:hover .tooltipText {
            opacity: 1;
        }
    `}
`;

const TooltipStyled = styled.div`
    position: absolute;
    bottom: 100%;
    ${({ $align }) => ($align === 'right' ? 'right' : 'left')}: 50%;
    transform: translate(
        ${({ $align }) =>
            $align === 'center'
                ? '-50%'
                : $align === 'right'
                ? '32px '
                : '-21px'},
        -25px
    );
    border: 1px solid ${({theme}) => theme.colors.primary.default};
    border-radius: 8px;
    padding: 16px 24px;
    min-width: 58px;
    text-align: center;
    font-size: ${({theme}) => theme.fontSizes[3]};
    color: #fff;
    pointer-events: none;
    font-family: ${({theme}) => theme.fonts.body};

    &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border: solid ${({theme}) => theme.colors.primary.default};
        border-width: 0 1px 1px 0;
        transform: rotate(45deg) translateX(-50%);
        transform-origin: 0;
        margin-top: -5px;
        background: ${({theme}) => theme.colors.base.black};;
        position: absolute;
        top: 100%;
        ${({ $align }) => ($align === 'right' ? 'right' : 'left')}: ${({
            $align,
        }) => ($align === 'center' ? '50%' : '20px')};
    }
`;

const TooltipUncoupled = ({
    children,
    text,
    innerRef,
    hover = true,
    borderColor = '#00aeef',
    fontSize = '18px',
    align = 'center',
    ...props
}) => {
    return (
        <TooltipWrapper ref={innerRef} $hover={hover} {...props}>
            <TooltipStyled
                className="tooltipText"
                $borderColor={borderColor}
                $fontSize={fontSize}
                $align={align}
            >
                {text}
            </TooltipStyled>
            {children}
        </TooltipWrapper>
    );
};

export const Tooltip = forwardRef((props, ref) => (
    <TooltipUncoupled innerRef={ref} {...props} />
));
