import styled from 'styled-components';

export const ScrollbarContent = styled.div`
    padding: 0 107px;
    height: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        padding: 0 50px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        padding: 0 24px;
    }
`;

export const ScrollbarMain = styled.div`
    position: relative;

    &:hover .track {
        opacity: 0.75;
    }
`;

export const ScrollbarWrapper = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
`;

export const ScrollbarInner = styled.div`
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    scroll-behavior: smooth;
`;

export const ScrollbarTrack = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
    user-select: none;
    width: 4px;
    min-height: 30px;
    max-height: 100%;
    background: ${({theme}) => theme.colors.primary.default};
    opacity: 0;
    transition: opacity 0.25s ease-in, width 0.2s ease-in-out;
    z-index: 99999;

    &:hover {
        width: 10px;
    }
`;
