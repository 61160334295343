import { Logo } from 'assets/images';
import { Flex, Button, Text } from 'theme-ui';
import { Link } from 'react-router-dom';

export const PartnerHeader = ({ scrollToForm }) => {
    return (
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                py: [24],
            }}
        >
            <Link to="/">
                <Logo sx={{ height: '32px', width: '150px' }} />
            </Link>
            <Flex style={{ gap: '16px' }}>
                <Button onClick={() => scrollToForm()} variant="tertiary">Request Access</Button>
                <Button variant="primary">
                    <Text as="a"
                        href={`${process.env.REACT_APP_API_BASE_URL}/login`}
                        target="__blank"
                        rel="noopener noreferrer"
                        sx={{
                            color: 'base.white',
                            textDecoration: 'none',
                        }}
                        >
                        Partner Login
                    </Text>
                </Button>
            </Flex>
        </Flex>
    );
};
