import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Field } from '~/Fields/FormikField';
import { Box, Button, Field as FieldUI, Flex, Heading, Label, Text } from 'theme-ui';
import { Select } from '~/Fields/Select';
import { getPartnerOptionsAsync, storePartnerRequestAsync } from 'features/appThunks';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { BenchmarkPlaceholder, InsightsReport } from 'assets/images';
import { ListStyled } from '~/Common/ImageList/styled';
import { Icon } from '~/Common/Icon';
import { Markdown } from '~/Common/Markdown';
import { useTheme } from 'styled-components';
import { useQuery } from 'hooks/useQuery';
import { PartnerHeader } from '~/PartnerHeader';

const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    industry: Yup.object().required('Industry is required'),
    lead_source: Yup.string(),
    terms_accepted: Yup.boolean().oneOf([true], 'You must accept the terms')
});

export const PartnerUncoupled = ({
    partner,
    partnerOptions,
    getPartnerOptionsAsync,
    storePartnerRequestAsync,
}) => {
    useEffect(() => {
        if (partnerOptions?.status === 'idle') {
            getPartnerOptionsAsync();
        }
    }, [partnerOptions, getPartnerOptionsAsync]);

    const handleSubmit = async (values, { setErrors }) => {
        try {
            await storePartnerRequestAsync({
                    ...values,
                    industry_id: values?.industry?.value,
                })
                .unwrap()
                .then(data => console.log(data));
        } catch (e) {
            toast.error(e?.data?.message);

            if (e.data.errors) {
                setErrors(e.data.errors)
            }
        }
    };

    const theme = useTheme()
    let query = useQuery()

    const content = partnerOptions?.data?.content;
    const headerContent = partnerOptions?.data?.headerContent;

    const formRef = useRef(null);

    function scrollToForm() {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return <>
        <PartnerHeader scrollToForm={scrollToForm} />
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: [80],
                alignItems: 'center',
                flexDirection: 'row',
                [`@media (max-width: ${theme.breakpoints[0]})`]: {
                    flexDirection: 'column',
                    py: 5,
                }
            }}
        >
            <Box
                sx={{
                    width: '82%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                    }
                }}
            >
                <Flex style={{flexDirection: 'column', gap: '64px'}}>
                    {headerContent ? <Markdown content={headerContent} /> : <>
                        <Flex style={{flexDirection: 'column', gap: '8px'}}>
                            <Heading variant="subheading" sx={{ mb: 2 }}>Partner Access</Heading>
                            <Heading variant="heading" sx={{fontSize: [48], lineHeight: '58px'}} >The ultimate tool <br />for business owners to <br />understand and maximize <br />their enterprise value</Heading>
                        </Flex>
                    </>}
                    <Button sx={{ mt: 5, alignSelf: 'flex-start' }} as="a" variant='primary' href="#about">Find Out More</Button>
                </Flex>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    position: 'relative',
                    top: '-20px',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }}
            >
                <InsightsReport />
            </Box>
        </Flex>
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: [120],
                gap: '102px',
                alignItems: ['flex-start', 'center'],
                flexDirection: ['column', 'row'],
            }}
            id="about"
        >
            <Box
                sx={{
                    width: '35%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }}
            >
                <BenchmarkPlaceholder />
            </Box>
            <Box
                sx={{
                    width: '58%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%'
                    }
                }}
            >
                {content ? <Markdown content={content} /> : <>
                    <Heading variant="heading" sx={{ mb: [40] }}>Increase the enterprise value for business owners in your community.</Heading>
                    <Text variant="bodyLg" sx={{ mb: 4 }}>Partner with Cultivate Advisors and bring the most talked about Enterprise Value tool to your community of business owners. By providing this tool, you'll receive the following benefits:</Text>
                    <ListStyled isGrid={true} style={{ marginTop: [40], gridTemplateColumns: 'auto auto', rowGap: '12px' }}>
                        {['Help businesses grow, so you grow', 'Provide value to the businesses in your network', 'Attract and retain more business owners', 'Better insight & data into the businesses you work with'].map((item, index) => (
                            <Flex as="li" key={index} sx={{gap: [16], alignItems: 'flex-start'}}>
                                <Icon icon="tick" sx={{ flexShrink: 0 }} />
                                <Text variant='body' sx={{ lineHeight: '25.6px' }}>{item}</Text>
                            </Flex>
                        ))}
                    </ListStyled>
                </>}
            </Box>
        </Flex>
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: [120],
                flexDirection: ['column', 'row'],
            }}
            id="register"
            ref={formRef}
        >
            <Box
                sx={{
                    width: '30%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                    }
                }}
            >
                <Heading variant='heading' sx={{marginBottom: [32]}}>Request access to start using this tool for free today!</Heading>
                <Text variant='bodyLead'>Apply by filling out the information below and we will create your account shortly.</Text>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%'
                    }
                }}
            >
                {partner.status === 'fulfilled' ?
                    <Heading>{t('pages.partner.success')}</Heading> :
                    <Formik
                        initialValues={{
                            company_name: '',
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            industry: '',
                            lead_source: '',
                            terms_accepted: false,
                            utm_source: query.get('utm_source'),
                            utm_campaign: query.get('utm_campaign')
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                    <Form>
                        <Flex sx={{flexDirection: 'column', gap: [24]}}>
                            <Field
                                label={t('pages.partner.companyName.label')+'*'}
                                placeholder={t(
                                    'pages.partner.companyName.placeholder'
                                )}
                                variant="contactInput"
                                name="company_name"
                                as={FieldUI}
                            />
                            <Field
                                label={t('pages.partner.firstName.label')+'*'}
                                placeholder={t(
                                    'pages.partner.firstName.placeholder'
                                )}
                                variant="contactInput"
                                name="first_name"
                                as={FieldUI}
                            />
                            <Field
                                label={t('pages.partner.lastName.label')+'*'}
                                placeholder={t(
                                    'pages.partner.lastName.placeholder'
                                )}
                                variant="contactInput"
                                name="last_name"
                                as={FieldUI}
                            />
                            <Field
                                label={t('pages.partner.email.label')+'*'}
                                placeholder={t(
                                    'pages.partner.email.placeholder'
                                )}
                                variant="contactInput"
                                name="email"
                                as={FieldUI}
                            />
                            <Field
                                label={t('pages.partner.phone.label')+'*'}
                                placeholder={t(
                                    'pages.partner.phone.placeholder'
                                )}
                                variant="contactInput"
                                name="phone"
                                as={FieldUI}
                            />                        
                            <Box sx={{mb: [8]}}>
                                <Label sx={{ mb: [8] }}>{t('pages.partner.industry.label')+'*'}</Label>
                                <Field
                                    placeholder={t(
                                        'pages.partner.industry.placeholder'
                                    )}
                                    name="industry"
                                    component={Select}
                                    options={partnerOptions?.data?.industries ?? []}
                                    errorSx={{
                                        top: '-28px'
                                    }}
                                />
                            </Box>            
                            <Field
                                label={t('pages.partner.source.label')}
                                variant="contactInput"
                                name="lead_source"
                                as={FieldUI}
                            />
                            <Label sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 'px',
                                position: 'relative',
                                paddingTop: '10px',
                            }}>
                                <Field
                                    variant="checkbox"
                                    name="terms_accepted"
                                    type="checkbox"
                                    separateError={true}
                                    errorSx={{position: 'absolute', bottom: '-24px'}}
                                />
                                <Text as="p" sx={{ ml: [8], pt: '6px' }}>
                                    I agree to Petra Coach <a href="https://petracoach.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                                    privacy policy</a>.
                                </Text>
                            </Label>
                            <Button type="submit" sx={{alignSelf: 'flex-start'}}>
                                {t('pages.partner.submit')}
                            </Button>
                        </Flex>
                    </Form>
                </Formik>}
            </Box>
        </Flex>
    </>
};

export const Partner = connect(
    ({ app }) => ({
        partner: app.partner,
        partnerOptions: app.partnerOptions,
    }),
    {
        getPartnerOptionsAsync,
        storePartnerRequestAsync,
    }
)(PartnerUncoupled);
