import { Logo } from 'assets/images';
import { Container, Flex } from 'theme-ui';
import { ContainerLayoutStyled } from './styled';
import { useTheme } from 'styled-components';
import { Footer } from '~/Footer/footer';

export const ContainerLayout = ({ children, headerType, hideDefaultHeader, ...props }) => {
    const theme = useTheme();
    return (
        <>
            <ContainerLayoutStyled {...props}>
                <Container>
                    {!hideDefaultHeader && (
                        <Flex
                            py={4}
                            sx={{
                                height: '80px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                [`@media (max-width: ${theme.breakpoints[0]})`]:
                                    {
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    },
                            }}
                        >
                            <Logo
                                sx={{ height: '32px', width: '150px' }}
                            />
                        </Flex>
                    )}
                    {children}
                </Container>
            </ContainerLayoutStyled>
            <Footer />
        </>
    );
};
