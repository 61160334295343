import { forwardRef, useRef, useState } from 'react';
import { Heading, Button, Input } from 'theme-ui';
import { useField } from 'formik';
import { ImageField } from '~/Fields/ImageField/index';
import { Range } from '~/Fields/Range';
import { Select } from '~/Fields/Select';
import { StyledField, ButtonWrapper } from './styled';
import { FinancialHistory } from '~/Fields/FinancialHistory';
import { Currency } from '~/Fields/Currency';
import { Error } from '~/Common/Error';
import { IndustriesSelect } from '~/Fields/IndustriesSelect';
import { Percentage } from '~/Fields/Percentage';
import { t } from 'i18next';
import { Number } from '~/Fields/Number';

const FieldUncoupled = ({
    field,
    inputProps,
    isActive,
    innerRef,
    onNext,
    afterChange,
    ...props
}) => {
    const [formikProps, { error }, { setValue }] = useField({
        name: field.name,
        ...props,
    });
    const fieldProps = {
        ...field.props,
        ...inputProps,
        ...formikProps,
    };
    const showHeading =
        field.type !== 'image' && field.type !== 'financial_history';
    const fieldRef = useRef(null);
    const isFilled = !!formikProps.value;

    const [focusedField, setFocusedField] = useState(null);

    const handleFocus = (fieldName) => setFocusedField(fieldName);
    const handleBlur = () => setFocusedField(null);

    if (!field.visible) {
        return null;
    }

    const renderField = () => {
        switch (field?.type ?? 'text') {
            case 'financial_history':
                return (
                    <FinancialHistory
                        {...fieldProps}
                        title={field.title}
                        onChange={(value) => {
                            setValue(value);
                        }}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
            case 'select':
                return (
                    <Select
                        {...fieldProps}
                        options={field.options}
                        onChange={({ value }) => {
                            setValue(value);
                            afterChange({ field, value });
                        }}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
            case 'industries':
                return (
                    <IndustriesSelect
                        {...fieldProps}
                        onChange={({ value }) => {
                            setValue(value);
                            afterChange({ field, value });
                        }}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
            case 'currency':
                return (
                    <Currency
                        onKeyUp={({ key }) =>
                            key === 'Enter' &&
                            afterChange({ field, value: fieldProps.value })
                        }
                        {...fieldProps}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
            case 'range':
                return (
                    <Range
                        {...fieldProps}
                        onChange={(value) => {
                            setValue(value);
                        }}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
            case 'image':
                return <ImageField {...fieldProps} onFocus={() => handleFocus(field.name)} onBlur={handleBlur} />;
            case 'percentage':
                return <Percentage {...fieldProps} onFocus={() => handleFocus(field.name)} onBlur={handleBlur} />;
            case 'number':
                return <Number {...fieldProps} onFocus={() => handleFocus(field.name)} onBlur={handleBlur} />;
            case 'text':
            default:
                return (
                    <Input
                        onKeyUp={({ key }) => console.log(key)}
                        {...fieldProps}
                        onFocus={() => handleFocus(field.name)}
                        onBlur={handleBlur}
                    />
                );
        }
    };

    return (
        <StyledField
            ref={(el) => {
                if (innerRef) {
                    innerRef(el);
                }
                fieldRef.current = el;
            }}
            $isActive={isActive}
            {...props}
        >
            {/* Lower the opacity of the heading when the input it focused */}
            {showHeading && (
                <Heading
                    as="h3"
                    sx={{
                        color: 'base.white',
                        fontFamily: 'body',
                        fontSize: [18],
                        fontWeight: 'body',
                        lineHeight: 'body',
                        mb: [24],
                        opacity: isFilled || focusedField === field.name ? 1 : 0.5,
                        transition: 'opacity 0.3s ease-in-out',
                    }}
                >
                    {field.title}
                </Heading>
            )}
            {renderField()}
            {field.image && <ImageField {...field.image} />}
            {error && <Error>{error}</Error>}
            {onNext && (
                <ButtonWrapper>
                    <Button sx={{width: '180px'}} variant='tertiary' onClick={onNext}>{t('buttons.next')}</Button>
                </ButtonWrapper>
            )}
        </StyledField>
    );
};

export const Field = forwardRef((props, ref) => (
    <FieldUncoupled innerRef={ref} {...props} />
));
