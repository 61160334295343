import { buttonBase } from './base-styles/button-base';

export const theme = {
    breakpoints: ['960px', '1137px'],
    sizes: {
        container: '1046px',
    },
    colors: {
        error: '#17B26A',
        errorLight: '#FEE4E2',
        warning: '#F1A043',
        warningLight: '#DCFAE6',
        success: '#00AEEF',
        successLight: '#DCFAE6',
        info: '#0070E0',
        primary: {
            default: '#EF7808',
            100: '#FDDEB2',
            200: '#FCC980',
            300: '#FBB34E',
            400: '#FAA229',
            500: '#EF7808',
            600: '#F58709',
            700: '#EF7808',
            800: '#E96807',
            900: '#DF4E07',
        },
        greyShades: {
            300: '#CECFD2',
        },
        disabled: '#94969C',
        base: {
            black: '#000000',
            white: '#FFFFFF',
        },
        type: {
            dark: '#000000',
            light: '#FFFFFF',
            default: '#333741',
            muted: '#94969C',
        },
        background: {
            dark: '#000000',
            disabled: '#F0F1F1',
        },
    },
    toast: {
        maxWidth: '800px',
        backgroundColor: '#000000',
        fontFamily: 'Montserrat, sans-serif',
        color: '#ffffff',
        border: '1px solid #333333',
        padding: '16px 24px',
        borderRadius: 0,
    },
    fonts: {
        body: 'Montserrat, -apple-system, BlinkMacSystemFont, Verdana, "Helvetica Neue", sans-serif',
        heading: 'Libre Baskerville, Georgia, serif',
    },
    fontSizes: [10, 14, 16, 18, 20, 28, 40],
    fontWeights: {
        thin: 300,
        body: 400,
        heading: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    lineHeights: {
        body: '27px',
        lead: '28px',
        heading: '48px',
    },
    space: {
        0: 0,
        4: 4,
        8: 8,
        12: 12,
        16: 16,
        24: 24,
        32: 32,
        40: 40,
        48: 48,
        56: 56,
        64: 64,
        72: 72,
        80: 80,
        120: 120,
    },
    text: {
        h1: {
            fontSize: [4, '40px'],
        },
        h2: {
            fontSize: [4, 5],
        },
        heading: {
            fontSize: [40],
            lineHeight: 'heading',
            fontWeight: 'body',
            color: 'white',
        },
        subheading: {
            fontSize: [18],
            lineHeight: 'body',
            color: 'primary.default',
            fontFamily: 'body',
            fontWeight: 'bold',
        },
        large: {
            fontSize: [6],
            fontWeight: 300,
        },
        boldHeading: {
            display: 'block',
            color: '#00AEEF',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '1.4',
        },
        body: {
            fontFamily: 'body',
            fontSize: [16],
            lineHeight: 'body',
            fontWeight: 'body',
            color: 'base.white',
        },
        bodyLg: {
            fontFamily: 'body',
            fontSize: [18],
            lineHeight: 'body',
            fontWeight: 'body',
            color: 'base.white',
        },
        bodyLead: {
            fontFamily: 'body',
            fontSize: [18],
            lineHeight: 'lead',
            fontWeight: 600,
            color: 'base.white',
        }
    },
    forms: {
        label: { fontFamily: 'body', fontWeight: 'body', mb: 8 },
        input: {
            outline: '1px solid rgba(206, 207, 210, 1)',
            border:'none',
            fontSize: [18],
            padding: [24],
            fontWeight: 'bold',
            lineHeight: 'body',
            fontFamily: 'body',
            color: 'base.white',
            opacity: 0.5,
            borderRadius: 0,
            '&:focus': {
                opacity: 1,
                outline: '2px solid rgba(239, 120, 8, 1)',
            },
            '&::placeholder': {
                fontWeight: 'body',
            },
        },
        checkbox: {
            display: 'inline-block',
            width: '24px',
            height: '24px',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'greyShades.300',
            position: 'relative',
            '&:checked': {
                backgroundColor: 'primary.default',
                borderColor: 'primary.default',
            },
            '&:checked::before': {
                content: '""',
                display: 'inline-block',
                width: '3px',
                height: '10px',
                border: '2px solid transparent',
                borderBottomColor: 'white',
                borderRightColor: 'white',
                position: 'absolute',
                bottom: '7px',
                left: '7px',
                transform: 'rotate(30deg)',
            },
        },
        transparent: {
            fontSize: '16px',
            padding: '6px',
            border: 'none',
            borderRadius: '2px',
            fontWeight: 300,
        },
        contactInput: {
            fontFamily: 'body',
            padding: [16],
            my: [8],
            outline: '1px solid',
            outlineColor: 'greyShades.300',
            fontSize: [16],
            fontWeight: 'body',
            borderRadius: 0,
            border: 'none',
            '&::placeholder': {
                color: 'hsl(0, 0%, 50%)',
                fontWeight: 300,
            },
            '&:focus': {
                outlineWidth: '2px',
                outlineColor: 'primary.default',
            }
        },
    },
    cards: {
        primary: {
            margin: '0 0 40px 0',
        },
    },
    buttons: {
        primary: {
            ...buttonBase.default,
            backgroundColor: 'primary.default',
            borderColor: 'primary.default',
            color: 'white',
            '&:hover': {
                backgroundColor: 'primary.800',
            },
            '&:disabled': {
                backgroundColor: 'background.disabled',
                borderColor: 'background.disabled',
                color: 'disabled',
                '&:hover': {
                    backgroundColor: 'background.disabled',
                    color: 'disabled',
                    borderColor: 'background.disabled',
                    cursor: 'default',
                },
            },
        },
        tertiary: {
            ...buttonBase.default,
            backgroundColor: 'base.black',
            borderColor: 'primary.default',
            color: 'white',
            '&:hover': {
                borderColor: 'primary.900',
            },
        },
        small: {
            primary: {
                ...buttonBase.small,
                backgroundColor: 'primary.default',
                borderColor: 'primary.default',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'primary.800',
                    color: 'primary.default',
                },
            },
            secondary: {
                ...buttonBase.small,
                backgroundColor: 'primary.default',
                borderColor: 'primary.default',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'primary.default',
                },
            },
        },
    },
};
