import { Flex, Text } from 'theme-ui';
import { Logo } from 'assets/images';
import { Link } from 'react-router-dom';

export const Footer = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            padding: '40px 120px',
        }}
    >
        <Link to="/">
            <Logo sx={{width: '108px', height: '24px'}} />
        </Link>
        <Text sx={{ fontFamily: 'body', fontSize: '10px', lineHeight: '12px' }}>
            &copy; {new Date().getFullYear()} Petra Coach.
        </Text>
    </Flex>
);
