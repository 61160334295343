import { Image } from 'theme-ui';
import { ReactComponent as LogoImage } from './logo.svg';
import { ReactComponent as DynamicRevaluationImage } from './dynamic-revaluation.svg';
import { ReactComponent as PropellerStaticImage } from './propeller.svg';
import { ReactComponent as GraphImage } from './graph.svg';
import InsightsReportImage from 'assets/images/insight-report-tilted.png';
import DownloadReportImage from 'assets/images/download-report.png';
import ExitOptionsGraphImage from 'assets/images/exit-graph.png';
import BenchmarkPlaceholderImage from 'assets/images/benchmark-placeholder.png';

/* SVGs - Use "as" property */

export const Logo = (props) => <Image {...props} as={LogoImage} />;

export const DynamicRevaluation = (props) => (
    <Image {...props} as={DynamicRevaluationImage} />
);

export const Graph = (props) => (
    <Image {...props} as={GraphImage} />
);

export const PropellerStatic = (props) => (
    <Image
        {...props}
        sx={{ minWidth: '280px', ...props.sx }}
        as={PropellerStaticImage}
    />
);

/* PNGs - Use "src" property */

export const DownloadReport = (props) => (
    <Image {...props} src={DownloadReportImage} />
);

export const InsightsReport = (props) => (
    <Image {...props} src={InsightsReportImage} />
);

export const ExitOptionsGraph = (props) => (
    <Image {...props} src={ExitOptionsGraphImage} />
);

export const BenchmarkPlaceholder = (props) => (
    <Image {...props} src={BenchmarkPlaceholderImage} />
);
