import { Heading } from 'theme-ui';
import { CardStyled, CardBody } from './styled';

export const Card = ({
    title,
    titleCentered,
    icon,
    iconSize,
    children,
    bsx,
    titleSx,
    ...props
}) => {
    if(titleCentered) {
        titleSx = { textAlign: 'center' }
    }
    return (
        <CardStyled {...props}>
            {title && (
                <Heading
                    sx={{
                        fontSize: [40],
                        fontWeight: 'body',
                        lineHeight: 'heading',
                        mb: [48],
                        ...titleSx,
                    }}
                >
                    {title}
                </Heading>
            )}
            <CardBody sx={bsx}>{children}</CardBody>
        </CardStyled>
    );
};
