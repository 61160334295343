import styled from 'styled-components';
import { Box } from 'theme-ui';

export const StepsLayoutStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 80px;
`;

export const StepsBody = styled(Box)`
    position: relative;
    flex: 1;
`;
