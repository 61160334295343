import ReactModal from 'react-modal';
import { Box, Button, Heading } from 'theme-ui';

ReactModal.setAppElement('#root');

export const Modal = ({
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    children,
    title,
    onOk,
    okText = 'Ok',
    onCancel,
    cancelText = 'Cancel',
    ...props
}) => {
    const hasButtons = onOk || onCancel;
    return (
        <ReactModal
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
                content: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    right: 'unset',
                    bottom: 'unset',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: '#000',
                    border: '1px solid #333',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '48px',
                    fontWeight: 600,
                    fontSize: '16px',
                    width: '540px',
                    height: 'auto',
                    color: 'white',
                    fontFamily: 'Montserrat, sans-serif'
                },
            }}
            {...props}
        >
            {title && (
                <Heading
                    variant="heading"
                    sx={{ whiteSpace: 'pre-wrap', color: 'white', fontFamily: 'heading', fontWeight: 'body' }}
                >
                    {title}
                </Heading>
            )}
            {children}
            {hasButtons && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        mt: '48px',
                        ...props.boxSx
                    }}
                >
                    {onCancel && (
                        <Button
                            variant="tertiary"
                            onClick={onCancel}
                            sx={{
                                minWidth: '210px',
                                width: '100%',
                                fontSize: '14px',
                                py: '24px',
                                px: '32px',
                                marginRight: [16]
                            }}
                        >
                            {cancelText}
                        </Button>
                    )}
                    {onOk && (
                        <Button
                            variant="primary"
                            onClick={onOk}
                            sx={{
                                minWidth: '210px',
                                width: '100%',
                                fontSize: '14px',
                                py: '24px',
                                px: '32px',
                            }}
                            type={props?.buttonType ?? 'button'}
                        >
                            {okText}
                        </Button>
                    )}
                </Box>
            )}
        </ReactModal>
    );
};
