import styled from 'styled-components';

export const StyledStepsNav = styled.div`
    display: flex;
    grid-gap: 16px;
    user-select: none;
    justify-content: center;
    order: -1;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        flex: 100%;
        order: 2;
        grid-gap: 38px;
    }
`;

export const StepNavItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
    color: ${({ theme, $isActive }) =>  $isActive ? theme.colors.base.white :  '#61646C'};
    font-family: ${({ theme }) => theme.fonts.body};
    & span {
        transition: opacity 0.3s ease-in-out;
    }

    &::before {
        content: attr(data-index);
        display: block;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border: 1px solid;
        border-color: ${({ theme, $isActive }) =>  $isActive ? theme.colors.primary.default :  'rgba(206, 207, 210, 0.502)'};
        transition: opacity 0.3s ease-in-out;
        font-family: ${({ theme }) => theme.fonts.heading};
    }

    &:not(:last-child)::after {
        content: '';
        display: block;
        height: 3px;
        width: 3px;
        background-color: ${({ theme }) => theme.colors.base.white};
        margin: 0 24px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        grid-gap: 8px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        display: block;
        transform: translateY(-16px);

        & span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 8px;
        }

        &:not(:last-child)::after {
            display: none;
        }
    }
`;
