import { Box, Button, Heading, Text, Image, Flex } from 'theme-ui';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
    getQuestionsAsync,
    getStepsAsync,
    startSubmissionAsync,
} from 'features/appThunks';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { ImageList } from '~/Common/ImageList';
import { useEffect } from 'react';
import { setStep, setUtmSource, setRefSource } from 'features/appSlice';
import { useQuery } from 'hooks/useQuery';

const enableRecaptcha = process.env.REACT_APP_ENV !== 'local';

const IntroUncoupled = ({
    step = null,
    steps,
    startSubmissionAsync,
    getStepsAsync,
    getQuestionsAsync,
    setStep,
    setUtmSource,
    setRefSource,
}) => {
    const captchaRef = useRef(null);
    const history = useHistory();
    const [introStep, setIntroStep] = useState(step ?? 1);
    let query = useQuery();

    if (
        query.get('utm_source') &&
        localStorage.getItem('utm_source') !== query.get('utm_source')
    ) {
        setUtmSource(query.get('utm_source'));
    }
    if (
        query.get('ref') &&
        localStorage.getItem('ref') !== query.get('ref')
    ) {
        setRefSource(query.get('ref'));
    }

    useEffect(() => {
        if (steps.status === 'idle') {
            getStepsAsync()
                .unwrap()
                .catch(() => {
                    setIntroStep('Error');
                });
        }
    }, [steps.status, getStepsAsync]);

    useEffect(() => {
        if (introStep === 2) {
            window.scrollTo(0, 0);
        }
    }, [introStep]);

    const startNewSubmission = async () => {
        try {
            const token = captchaRef.current
                ? await captchaRef.current.executeAsync()
                : undefined;

            await startSubmissionAsync(token).unwrap();
            await getQuestionsAsync().then(() => setStep(0));

            history.push('/submission');
        } catch (e) {
            console.log(e);
            toast.error(e.response?.message ?? t('errors.newSubmission'));
        }
    };

    return (
        <Box
            sx={{
                marginY: [40],
            }}
        >
            <Heading variant="subheading">
                {t(`pages.intro.step${introStep}.heading`)}
            </Heading>
            <Text
                as="p"
                variant="large"
                sx={{
                    whiteSpace: 'pre-wrap',
                    fontSize: [40],
                    marginTop: [8],
                    lineHeight: ['heading'],
                    fontWeight: 'body',
                    fontFamily: 'heading',
                }}
            >
                {t(`pages.intro.step${introStep}.subheading`)}
            </Text>
            {introStep === 'Error' && (
                <Button
                    mt={4}
                    sx={{ minWidth: '190px', display: 'block' }}
                    onClick={() => window.location.reload()}
                >
                    {t('pages.intro.stepError.button')}
                </Button>
            )}
            {introStep === 1 && (
                <Flex
                    sx={{
                        flexDirection: 'column',
                        gap: [40],
                        marginY: [40],
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            alignSelf: 'stretch',
                        }}
                    >
                        <Image sx={{maxWidth: '100%'}} src="/images/intro-graph.png" />
                    </Box>

                    <ImageList
                        sx={{ mt: '32px' }}
                        list={t('pages.intro.step1.list', {
                            returnObjects: true,
                        })}
                    >
                        {t('pages.intro.step1.listContent')}
                    </ImageList>
                    <Button
                        variant="primary"
                        mt={4}
                        sx={{ minWidth: '190px' }}
                        onClick={() => {
                            setIntroStep(2);
                            history.push('/introduction');
                        }}
                    >
                        {t('pages.intro.step1.button')}
                    </Button>
                </Flex>
            )}
            {introStep === 2 && (
                <>
                    <Box>
                        {enableRecaptcha && (
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={
                                    process.env
                                        .REACT_APP_RECAPTCHA_SITE_KEY
                                }
                                size="invisible"
                            />
                        )}
                        <Flex sx={{
                            flexDirection: 'column',
                            gap: [16],
                            marginY: [64],
                        }}>
                            {Array.isArray(steps.data) &&
                                steps.data.map((step, index) => (
                                    <Flex
                                        key={index}
                                        sx={{                                           
                                            alignItems: 'center',
                                            gap: [24],
                                            padding: [24],
                                            border: '1px solid rgba(255, 255, 255, 0.5)',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: '1px solid',
                                                borderColor: 'primary.default',
                                                height: '40px',
                                                width: '40px',
                                                flexShrink: 0,
                                                textAlign: 'center',
                                                lineHeight: '28.8px',
                                                fontSize: '24px',
                                                fontWeight: 400,
                                                zIndex: 90,
                                                position: 'relative',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontFamily: 'heading',
                                            }}
                                        >
                                            {index + 1}
                                        </Box>
                                        <Text
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                flexShrink: 0,
                                                lineHeight: '24px',
                                                fontFamily: 'body',
                                                minWidth: '132px',
                                            }}
                                        >
                                            {step.title}
                                        </Text>
                                        <Text
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '25.6px',
                                                fontFamily: 'body',
                                            }}
                                        >
                                            {step.description}
                                        </Text>
                                    </Flex>
                                ))}
                        </Flex>  
                        <Button
                            variant='tertiary'
                            mt={4}
                            sx={{ minWidth: '190px' }}
                            onClick={startNewSubmission}
                        >
                            {t('pages.intro.step2.button')}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export const Intro = connect(({ app }) => ({ steps: app.steps }), {
    startSubmissionAsync,
    getStepsAsync,
    getQuestionsAsync,
    setStep,
    setUtmSource,
    setRefSource,
})(IntroUncoupled);
