import { Form, Formik } from 'formik';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Text, Field as FieldUI } from 'theme-ui';
import { Field } from '~/Fields/FormikField';
import { Modal } from '~/Modals/Modal';
import * as Yup from 'yup';
import { saveProgressAsync } from 'features/appThunks';
import toast from 'react-hot-toast';

const RouterPromptUncoupled = ({
    saveProgressAsync,
    isFinalised,
    email,
    when = true,
    cancelText = 'routerPrompt.buttons.cancel',
    okText = 'routerPrompt.buttons.confirm',
    onOK = () => true,
    onCancel = () => false,
}) => {
    const unblock = useRef(() => {});
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const [requireEmail, setRequireEmail] = useState(true)

    const formRef = useRef(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address'),
    });

    useEffect(() => {
        unblock.current = history.block((prompt) => {
            if (when) {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return false;
            }
            return true;
        });
        return () => unblock.current();
    }, [history, when]);

    useEffect(() => {
        setRequireEmail(!(isFinalised || email))
    }, [isFinalised, email])

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                unblock.current();
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    showPrompt && isFinalised && handleOK()

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                unblock.current();
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    const handleSubmit = async (values, { setErrors }) => {
        try {
            await saveProgressAsync(values)
                .unwrap()
                .then(() => handleOK());
        } catch (e) {
            toast.error(e?.data?.message);

            if (e.data.errors) {
                setErrors(e.data.errors)
            }
        }
    };

    return showPrompt ? (
        <Modal
            title={
                requireEmail
                    ? t('routerPrompt.default.heading')
                    : t('routerPrompt.finalised.heading')
            }
            isOpen={showPrompt}
            onRequestClose={() => setShowPrompt(false)}
            onOk={() => requireEmail ? formRef.current?.handleSubmit() : handleOK()}
            okText={t(okText)}
            onCancel={handleCancel}
            cancelText={t(cancelText)}
            closable={false}
            boxSx={{
                mt: 2,
            }}
            buttonType={requireEmail ? 'submit' : 'button'}
        >
            <Text
                sx={{
                    color: 'white',
                    fontWeight: 'body',
                    fontSize: 'body',
                    marginY: [40],
                    whiteSpace: 'pre-wrap'
                }}
            >
                {isFinalised
                    ? t('routerPrompt.finalised.content')
                    : (requireEmail ? t('routerPrompt.default.content') : t('routerPrompt.default.email'))}
            </Text>
            {requireEmail && <Formik
                innerRef={formRef}
                initialValues={{email: '',}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form style={{ width: '100%', margin: '0 0 40px 0' }}>
                    <Field
                        label={t('fields.email.label')}
                        placeholder={t(
                            'fields.email.placeholder'
                        )}
                        variant="contactInput"
                        name="email"
                        as={FieldUI}
                        boxSx={{
                            width: '100%',
                            mt: 4,
                        }}
                    />
                </Form>
            </Formik>}
        </Modal>
    ) : null;
};

export const RouterPrompt = connect(({ app }) => ({
    isFinalised: app?.submission?.isFinalised,
    email: app?.submission?.data?.email,
}), { saveProgressAsync })(RouterPromptUncoupled);
