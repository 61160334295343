export const buttonBase = {
  default: {
    fontFamily: 'body',
    paddingX: [16],
    paddingY: [12],
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    fontWeight: 400,
    border: '1px solid transparent',
    borderRadius: 0,
  },
  small: {
    padding: [12],
    lineHeight: '14px',
    fontWeight: 400,
    border: '1px solid transparent',
    borderRadius: 0,
  },
  icon: {
    default: {
      width: [48],
      height: [48],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid transparent',
      borderRadius: 0,
    },
    small: {
      width: [40],
      height: [40],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid transparent',
      borderRadius: 0,
    },
  }
}