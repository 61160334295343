import { Box } from 'theme-ui';
import { Icon } from '../Icon';
import {
    ImageListStyled,
    ImageListChildren,
    ListStyled,
    ListItemStyled,
} from './styled';
export const ImageList = ({
    children,
    list,
    image: Image,
    imageWidth = '307px',
    ...props
}) => {
    return (
        <ImageListStyled {...props}>
            <Box>
                <ImageListChildren>{children}</ImageListChildren>
                {Array.isArray(list) && (
                    <ListStyled isGrid={!Image}>
                        {list.map((item, index) => (
                            <ListItemStyled key={index}>
                                <Icon icon="tick" sx={{ flexShrink: 0 }} />
                                {item}
                            </ListItemStyled>
                        ))}
                    </ListStyled>
                )}
            </Box>
            {Image && (
                <Box
                    sx={{
                        minWidth: imageWidth,
                        maxWidth: imageWidth,
                        margin: ['32px auto 0 auto', null, 0],
                    }}
                >
                    <Image
                        sx={{
                            width: '100%',
                            objectFit: 'scale-down',
                        }}
                    />
                </Box>
            )}
        </ImageListStyled>
    );
};
