import { useEffect, useState } from 'react';
import { Heading } from 'theme-ui';
import { PropellerImage } from './PropellerImage';
import { PropellerStyled } from './styled';

export const propellerSections = {
    dependency: {
        name: 'Dependency',
        description: 'Leveling Up The Team To Distribute Ownership',
    },
    strategy: {
        name: 'Strategy',
        description: 'Define Collectively Where We Are Going',
    },
    communication: {
        name: 'Communication',
        description: 'The Flow And Speed Of Information',
    },
    engagement: {
        name: 'Engagement',
        description: 'The People Inside The Organization',
    },
    accountability: {
        name: 'Accountability',
        description: 'Doing What We Say & Taking Ownership',
    },
    productivity: {
        name: 'Productivity',
        description: "Measurable Progress Towards Strategy",
    },
};

export const Propeller = ({ type, ...props }) => {
    const [state, setState] = useState({});

    useEffect(() => {
        if (propellerSections[type]) {
            setState(propellerSections[type]);
        }
    }, [type]);

    return (
        <PropellerStyled {...props}>
            <Heading
                sx={{
                    color: '#00AEEF',
                    fontSize: '28px',
                    fontWeight: 400,
                    mb: '24px',
                }}
            >
                {state.name ?? ''}
            </Heading>
            <PropellerImage type={type} />
        </PropellerStyled>
    );
};
