import { Box } from 'theme-ui';
import { Range as ReactRange, getTrackBackground } from 'react-range';
import { Tooltip } from '~/Common/Tooltip';
import { useEffect } from 'react';
import { useCallback } from 'react';

const dotSx = {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '4px',
    height: '4px',
    bg: '#fff',
    opacity: '0.5',
    borderRadius: '50%',
    top: '2px',
    zIndex: 999,
    pointerEvents: 'none',
    userSelect: 'none',
};

const getValueLabel = (value) => {
    switch (value) {
        case 2:
            return 'Sometimes';
        case 3:
            return '50/50';
        case 4:
            return 'Often';
        case 5:
            return 'Always';
        default:
            return 'Never';
    }
};

export const Range = ({
    min = 1,
    max = 5,
    step = 1,
    onChange,
    value,
    disabled,
    allowNull = true,
    ...props
}) => {
    const trackColour = disabled ? '#8B8B97' : '#EF7808';
    const realMin = min - (allowNull ? 1 : 0);
    const isNull =
        isNaN(value) || value === null || (allowNull && value === realMin);

    useEffect(() => {
        if (!allowNull && (isNaN(value) || value === null) && !disabled) {
            // Set value to minimum when field is active
            onChange(min);
        }
    }, [allowNull, disabled, value, min, onChange]);

    const handleChange = useCallback(
        (values) => {
            if (typeof onChange === 'function') {
                onChange(
                    allowNull && values[0] === realMin ? null : values[0]
                );
            }
        },
        [allowNull, onChange, realMin]
    );

    return (
        <Box sx={{ pt: '64px' }}>
            <Box
                sx={{
                    position: 'relative',
                    mt: 3,
                    px: '10px',
                    background: `linear-gradient(to right, ${trackColour} 0px, ${trackColour} 15px, rgba(255,255,255,0.2) 16px)`,
                    borderRadius: '4px',
                    '&::before': {
                        ...dotSx,
                        left: '8px',
                    },
                    '&::after': {
                        ...dotSx,
                        right: '8px',
                    },
                }}
            >
                <ReactRange
                    step={step}
                    min={realMin}
                    max={max}
                    values={[value ?? realMin]}
                    onChange={handleChange}
                    renderTrack={({ props, children }) => (
                        <Box
                            {...props}
                            disabled={disabled}
                            style={{
                                ...props.style,
                                background: getTrackBackground({
                                    min: realMin,
                                    max: max,
                                    values: [value ?? realMin],
                                    colors: [trackColour, 'transparent'],
                                    direction: props.direction,
                                    rtl: props.rtl,
                                }),
                            }}
                            sx={{
                                height: '8px',
                                width: '100%',
                                '&::before': {
                                    ...dotSx,
                                    left: '20%',
                                    marginLeft: '-2px',
                                },
                                '&::after': {
                                    ...dotSx,
                                    right: '20%',
                                    marginRight: '-2px',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    ...dotSx,
                                    left: '40%',
                                    marginLeft: '-2px',
                                }}
                            >
                                &nbsp;
                            </Box>
                            <Box
                                sx={{
                                    ...dotSx,
                                    left: '60%',
                                    marginLeft: '-2px',
                                }}
                            >
                                &nbsp;
                            </Box>
                            {children}
                        </Box>
                    )}
                    renderThumb={({ props, value }) => (
                        <Tooltip
                            {...props}
                            text={
                                isNull
                                    ? 'Slide to select'
                                    : `${value} - ${getValueLabel(value)}`
                            }
                            hover={false}
                            // borderColor={disabled ? '#C4C4C4' : undefined}
                            sx={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: isNull
                                    ? '#8B8B97'
                                    : trackColour,
                                borderRadius: '50%',
                                boxShadow:
                                    '0px 1px 2px rgba(0, 0, 0, 0.2)',
                                whiteSpace: 'nowrap',
                                '&:focus-visible': {
                                    outlineColor: (theme) =>  theme.colors.base.white,
                                }
                            }}
                            align={isNull ? 'left' : 'center'}
                        />
                    )}
                    {...props}
                />
            </Box>
        </Box>
    );
};
