import styled from 'styled-components';
import { Box } from 'theme-ui';

const ErrorWrapper = styled(Box)`
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error};
`;

export const Error = ({ children, sx, ...props }) => (
    <ErrorWrapper
        sx={{
            marginTop: '8px',
            fontFamily: 'body',
            color: 'primary.default',
            fontWeight: 'thin',
            fontStyle: 'italic',
            fontSize: [14],
            lineHeight: '19.6px',
            textAlign: 'left',
            ...sx,
        }}
        {...props}
    >
        {children}
    </ErrorWrapper>
);
