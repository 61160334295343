import styled from 'styled-components';
import { Box } from 'theme-ui';

export const ImageListStyled = styled(Box)`
    margin: 0 !important;
    display: flex;
    align-items: center;
    grid-gap: 46px;
    color: ${({ theme }) => theme.colors.type.light};
    font-family: ${({ theme }) => theme.fonts.body};
    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        display: block;
    }
`;

export const ImageListChildren = styled.div`
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary.default};
`;

export const ListStyled = styled.ul`
    list-style: none;
    padding: 0;
    column-gap: 32px;
    row-tap: 12px;
    margin-top: 40px;
    margin-bottom: 0;
    ${({ isGrid }) =>
        isGrid
            ? `
        display: grid;

        @media(min-width: ${({ theme }) => theme.breakpoints[0]}) {
            grid-template-columns: repeat(2, 1fr);
        }
    `
            : ''}
`;

export const ListItemStyled = styled.li`
    display: flex;
    gap: 12px;
    line-height: 27px;
    padding: 8px 0;
`;
