import styled from 'styled-components';
import { Icon } from '~/Common/Icon';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

const LinkButtonStyled = styled((props) => <Box as={Link} {...props} />)`
    display: flex;
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    color: ${({ theme }) => theme.colors.base.white};
    text-decoration: none;
    background: none;
    border: none;
    user-select: none;
    line-height: 16px;
    letter-spacing: 0.8px;
`;

const LinkButtonText = styled.div`
    position: relative;
`;

export const LinkButton = ({ children, icon, iconPosition, ...props }) => {
    const iconAfter =
        typeof iconPosition === 'string' &&
        iconPosition.toLowerCase() === 'right';
    return (
        <LinkButtonStyled {...props}>
            {!iconAfter && icon && (
                <Flex sx={{width: 20, height: 20, marginRight: 12, justifyContent: 'center', alignItems: 'center'}}>
                    <Icon
                    sx={{
                        '& svg path': {
                            stroke: (theme) =>
                                theme.colors.base.white,
                        },
                    }}
                    icon={icon}
                />
                </Flex>
            )}
            <LinkButtonText className="buttonText">
                {children}
            </LinkButtonText>
            {iconAfter && icon && (
                <Icon
                    sx={{
                        marginLeft: 12,
                        '& svg path': {
                            stroke: (theme) =>
                                theme.colors.primary.default,
                        },
                    }}
                    icon={icon}
                />
            )}
        </LinkButtonStyled>
    );
};
