import styled from 'styled-components';

export const GetReportFormStyled = styled.div`
    position: relative;
    padding: 64px;
    border: 2px solid #EF7908;
    border-radius: 0;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: center;
`;
