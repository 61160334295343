import ReactSelect from 'react-select';
import { Icon } from '~/Common/Icon';
import { Box  } from 'theme-ui';

export const customStyles = {
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
        ...provided,
        padding: 20,
        backgroundColor: isSelected
            ? 'rgba(255, 255, 255, 0.1)'
            : 'base.black',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '16px',
        fontSize: 18,
    }),
    control: (provided, state) => ({
        ...provided,
        outline: state.isFocused
            ? '2px solid #EF7808'
            : '1px solid #CECFD2',
        background: 'transparent',
        borderRadius: 0,
        border: 'none',
        fontFamily: 'Montserrat',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    input: (provided) => ({
        ...provided,
        color: '#dedede',
    }),
    menu: () => ({
        background: 'black',
        border: '1px solid #CECFD2',
    }),
    menuList: (provided) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Montserrat',
    }),
};

export const selectArrow = {
    DropdownIndicator: () => {
        return (
            <Box px="24px">
                <Icon icon="dropdown" />
            </Box>
        );
    },
};

export const Select = ({
    value,
    options,
    menuShouldScrollIntoView,
    isSearchable = false,
    field,
    form,
    ...props
}) => {
    const findOption = (name) => {
        if (!Array.isArray(options)) {
            return undefined;
        }
        return options.find((option) => option.value === name);
    };

    return (
        <ReactSelect
            menuPosition="fixed"
            components={selectArrow}
            styles={customStyles}
            options={options}
            menuShouldScrollIntoView={menuShouldScrollIntoView === true}
            value={typeof value !== 'object' ? findOption(value) : value}
            isSearchable={isSearchable}
            menuPlacement="auto"
            {...(form && field
                ? {
                    onChange: (value) =>
                        form.setFieldValue(field.name, value),
                    }
                : {})}
            {...props}
        />
    );
};
