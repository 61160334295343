import { finaliseEntryAsync } from 'features/appThunks';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import {
    Box,
    Button,
    Field as FieldUI,
    Heading,
    Text,
    Link,
    Flex,
    Input,
} from 'theme-ui';
import { Icon } from '~/Common/Icon';
import { Loading } from '~/Common/Loading';
import { Field } from '~/Fields/FormikField';
import { GetReportFormStyled } from './styled';
import ReactMarkdown from 'react-markdown';
import { IndustriesSelect } from '~/Fields/IndustriesSelect';

const phoneRegExp = /^\(\d{3}\)-\d{3}-\d{4}$/;

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter a valid business email'),
    industry_id: Yup.string().required('Please select your industry'),
    mobile: Yup.string()
        .matches(phoneRegExp, 'Mobile number is not valid')
        .required('Mobile number is required'),
    isCheckboxChecked: Yup.boolean().oneOf(
        [true],
        'You must accept the terms'
    ),
});

const GetReportFormUncoupled = ({
    submission,
    utmSource,
    utmRef,
    finaliseEntryAsync,
    ...props
}) => {
    const [isLoading, setLoading] = useState(false);
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [mobile, setMobile] = useState(submission?.data?.phone_number ?? '');

    const handleMobileChange = (e, setFieldValue) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 3 && value.length <= 6) {
            value = `(${value.slice(0, 3)})-${value.slice(3)}`;
        } else if (value.length > 6) {
            value = `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
        setMobile(value);
        setFieldValue('mobile', value);
    };

    const handleSubmit = async (values, { errors, setErrors }) => {
        setLoading(true);
        const formattedValues = {
            ...values,
            mobile: values.mobile.replace(/\D/g, ''),
        };
        await finaliseEntryAsync(formattedValues)
            .unwrap()
            .catch((e) => {
                toast.error(e.data.message);
                setErrors(
                    Object.entries(e.data.errors).reduce((errors, i) => {
                        errors[i[0]] = i[1][0];
                        return errors;
                    }, {})
                );
            })
            .finally(() => setLoading(false));
    };

    return (
        <GetReportFormStyled {...props}>
            <Icon
                icon="download"
                sx={{
                    bg: 'primary.default',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    marginLeft: '-30px',
                    marginTop: '-30px',
                }}
            />
            {isLoading ? (
                <Box sx={{ position: 'relative', py: '100px' }}>
                    <Loading />
                </Box>
            ) : submission.isFinalised ? (
                <Box mt={4}>
                    <Heading
                        sx={{
                            mb: '24px',
                            textAlign: 'center',
                            fontWeight: 'normal',
                        }}
                    >
                        {t(
                            submission.data?.landing_page?.title ??
                                'pages.results.fulfilled.form.heading'
                        )}
                    </Heading>

                    {submission.data?.landing_page?.description ? (
                        <Box
                            sx={{
                                textAlign: 'center',
                                '> ul': {
                                    pt: 3,
                                    textAlign: 'left',
                                    lineHeight: '1.5',
                                    mx: 'auto',
                                    listStyle: 'none',
                                    pl: [3, 5],
                                    pr: [3, 5],
                                },
                                '> ul li': {
                                    position: 'relative',
                                    pl: 4,
                                    mb: 3,
                                },
                                '> ul li:before': {
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='%2300AEEF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    top: '8px',
                                    width: '20px',
                                    height: '20px',
                                },
                            }}
                        >
                            <ReactMarkdown>
                                {submission.data.landing_page?.description}
                            </ReactMarkdown>
                        </Box>
                    ) : (
                        <Text
                            sx={{
                                fontFamily: 'body',
                                fontSize: [18],
                                lineHeight: 'body',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {t('pages.results.fulfilled.form.content')}
                        </Text>
                    )}

                    <Flex
                        mt={32}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: ['column', 'row'],
                        }}
                    >
                        {submission.data?.landing_page?.url ? (
                            <Button
                                as={Link}
                                target="_blank"
                                sx={{
                                    display: 'inline-flex',
                                    mb: 3,
                                    mr: [0, 2],
                                    width: ['100%', 'auto'],
                                    minWidth: ['100px', '300px'],
                                }}
                                href={submission.data.landing_page?.url}
                            >
                                <Icon icon="phone" size="18px" />
                                {t(
                                    submission.data.landing_page?.button ??
                                        'pages.results.fulfilled.form.landing_button'
                                )}
                            </Button>
                        ) : null}

                        <Button
                            as={Link}
                            target="_blank"
                            sx={{
                                display: 'inline-flex',
                                mb: 3,
                                width: ['100%', 'auto'],
                                minWidth: ['100px', '300px'],
                                justifyContent: 'center',
                            }}
                            href={submission.data.report}
                        >
                            {t('pages.results.fulfilled.form.button')}
                        </Button>
                    </Flex>

                    <Text
                        mt={4}
                        as="p"
                        sx={{
                            fontSize: 18,
                            fontFamily: 'body',
                            paddingTop: '24px',
                            color: 'disabled',
                        }}
                    >
                        {t(
                            submission.data.landing_page?.sendoff ??
                                'pages.results.fulfilled.form.sendoff'
                        )}
                    </Text>
                </Box>
            ) : null}
            {!submission.isFinalised && (
                <Box sx={{ display: isLoading ? 'none' : 'block' }}>
                    <Formik
                        initialValues={{
                            first_name: submission?.data?.first_name ?? '',
                            last_name: submission?.data?.last_name ?? '',
                            email: submission?.data?.business_email ?? '',
                            mobile: submission?.data?.phone_number ?? '',
                            industry_id: submission?.data?.industry ?? '',
                            ref: utmRef,
                            privacy: false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, isValid, setFieldValue  }) => (
                            <>
                                <Heading
                                    sx={{
                                        mb: '13px',
                                        lineHeight: '33.6px',
                                        fontWeight: 'body',
                                    }}
                                >
                                    {t(
                                        'pages.results.complete.form.heading'
                                    )}
                                </Heading>
                                <Text>
                                    {t(
                                        'pages.results.complete.form.content'
                                    )}
                                </Text>
                                <Box
                                    className="get-report-form"
                                    as={Form}
                                    sx={{
                                        mt: '40px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '24px',
                                    }}
                                >
                                    <Field
                                        label={t('fields.firstName.label')}
                                        placeholder={t(
                                            'fields.firstName.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="first_name"
                                        as={FieldUI}
                                    />

                                    <Field
                                        label={t('fields.lastName.label')}
                                        placeholder={t(
                                            'fields.lastName.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="last_name"
                                        as={FieldUI}
                                    />

                                    <Field
                                        label={t(
                                            'fields.businessEmail.label'
                                        )}
                                        placeholder={t(
                                            'fields.businessEmail.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="email"
                                        as={FieldUI}
                                    />

                                    <Field
                                        label={t('fields.phone.label')}
                                        placeholder={t(
                                            'fields.phone.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="mobile"
                                        as={FieldUI}
                                        value={mobile}
                                        onChange={(e) => handleMobileChange(e, setFieldValue)}
                                    />
                                    <Field
                                        label={t('fields.industry.label')}
                                        placeholder={t(
                                            'fields.industry.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="industry_id"
                                        as={FieldUI}
                                        component={IndustriesSelect}
                                    />

                                    <label
                                        style={{
                                            display: 'flex',
                                            marginTop: '16px',
                                            textAlign: 'left',
                                            fontFamily: 'Montserrat',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            lineHeight: '16.8px',
                                        }}
                                    >
                                        <Input
                                            variant="checkbox"
                                            id="privacy"
                                            name="privacy"
                                            type="checkbox"
                                            checked={isCheckboxChecked}
                                            onChange={(e) =>
                                                setCheckboxChecked(
                                                    e.target.checked
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                        <Text as="span" sx={{paddingTop: '3px'}}>
                                            {t(
                                                'pages.results.complete.disclaimer'
                                            )}
                                            <a
                                                href="https://petracoach.com/privacy-policy/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    marginLeft: '5px',
                                                    color: 'white',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {t(
                                                    'pages.results.complete.disclaimerLinkText'
                                                )}
                                            </a>
                                        </Text>
                                    </label>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        sx={{
                                            minWidth: ['unset', '345px'],
                                            width: ['100%', 'auto'],
                                            alignSelf: 'center',
                                            paddingY: [9]
                                        }}
                                        disabled={
                                            !isValid ||
                                            !isCheckboxChecked ||
                                            !submission.data.can_finalise
                                        }
                                    >
                                        {t(
                                            'pages.results.complete.form.button'
                                        )}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Formik>
                </Box>
            )}
        </GetReportFormStyled>
    );
};

export const GetReportForm = connect(
    ({ app }) => ({
        submission: app.submission,
        utmSource: app.utmSource ?? localStorage.getItem('utm_source'),
        utmRef: app.ref ?? localStorage.getItem('ref'),
    }),
    {
        finaliseEntryAsync,
    }
)(GetReportFormUncoupled);
