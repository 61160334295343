import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { customStyles, selectArrow } from './Select';
import { Text } from 'theme-ui';

const IndustriesSelectUncoupled = ({ industries, field, form, ...props }) => {
    return (
        <div>
            <Text
                as="div"
                sx={{
                    fontFamily: 'body',
                    textAlign: 'left',
                    marginBottom: [12]
                }}
            >{props.label}</Text>
            <ReactSelect
                placeholder="Please Select Your Industry"
                isSearchable
                options={industries.data ?? []}
                name={field.name}
                value={industries.data ? industries.data.find(option => option.value === field.value) : ''}
                onChange={(newValue) => form.setFieldValue(field.name, newValue.value)}
                onBlur={field.onBlur}
                {...props}
                styles={{
                    ...customStyles,
                    valueContainer: (provided) => ({
                        ...provided,
                        padding: '16px',
                        textAlign: 'left', 
                    }),
                    input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                    }),
                    option: (provided, state) => ({
                        ...customStyles.option(provided, state),
                        textAlign: 'left',
                    }),
                    control:    (provided, state) => ({
                        ...customStyles.control(provided, state),
                        outlineColor: state.isFocused? '#EF7808' : '#CECFD2'
                    }),
                }}
                components={selectArrow}
        />
        </div>
    );
};

export const IndustriesSelect = connect(({ app }) => ({
    industries: app.industries,
}))(IndustriesSelectUncoupled);
