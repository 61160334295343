import styled from 'styled-components';
import { Box, Card } from 'theme-ui';

export const CardStyled = styled(Card)`
    padding: 40px 24px;
    background-color: ${({ theme }) => {
        return theme.colors.white;
    }};
    border-radius: 8px;
`;

export const CardBody = styled(Box)``;
